<template>
	  <path id="Vector (Stroke)_41" fill-rule="evenodd" clip-rule="evenodd"
            d="M183.519 327H275.031L309.547 396.033L275.031 465.065H183.513L149 395.891L183.519 327ZM190.232 337.871L161.154 395.902L190.238 454.194H268.312L297.393 396.033L268.312 337.871H190.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_42" fill-rule="evenodd" clip-rule="evenodd"
            d="M321.519 91H413.031L447.547 160.033L413.031 229.065H321.513L287 159.891L321.519 91ZM328.232 101.871L299.154 159.902L328.238 218.194H406.312L435.393 160.033L406.312 101.871H328.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_43" fill-rule="evenodd" clip-rule="evenodd"
            d="M321.519 250H413.031L447.547 319.033L413.031 388.065H321.513L287 318.891L321.519 250ZM328.232 260.871L299.154 318.902L328.238 377.194H406.312L435.393 319.033L406.312 260.871H328.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_44" fill-rule="evenodd" clip-rule="evenodd"
            d="M321.519 406H413.031L447.547 475.033L413.031 544.065H321.513L287 474.891L321.519 406ZM328.232 416.871L299.154 474.902L328.238 533.194H406.312L435.393 475.033L406.312 416.871H328.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_45" fill-rule="evenodd" clip-rule="evenodd"
            d="M461.519 11H553.031L587.547 80.0326L553.031 149.065H461.513L427 79.8915L461.519 11ZM468.232 21.8713L439.154 79.9018L468.238 138.194H546.312L575.393 80.0325L546.312 21.8713H468.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_46" fill-rule="evenodd" clip-rule="evenodd"
            d="M461.519 170H553.031L587.547 239.033L553.031 308.065H461.513L427 238.891L461.519 170ZM468.232 180.871L439.154 238.902L468.238 297.194H546.312L575.393 239.033L546.312 180.871H468.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_47" fill-rule="evenodd" clip-rule="evenodd"
            d="M461.519 327H553.031L587.547 396.033L553.031 465.065H461.513L427 395.891L461.519 327ZM468.232 337.871L439.154 395.902L468.238 454.194H546.312L575.393 396.033L546.312 337.871H468.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_48" fill-rule="evenodd" clip-rule="evenodd"
            d="M598.519 91H690.031L724.547 160.033L690.031 229.065H598.513L564 159.891L598.519 91ZM605.232 101.871L576.154 159.902L605.238 218.194H683.312L712.393 160.033L683.312 101.871H605.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_49" fill-rule="evenodd" clip-rule="evenodd"
            d="M598.519 250H690.031L724.547 319.033L690.031 388.065H598.513L564 318.891L598.519 250ZM605.232 260.871L576.154 318.902L605.238 377.194H683.312L712.393 319.033L683.312 260.871H605.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_50" fill-rule="evenodd" clip-rule="evenodd"
            d="M598.519 406H690.031L724.547 475.033L690.031 544.065H598.513L564 474.891L598.519 406ZM605.232 416.871L576.154 474.902L605.238 533.194H683.312L712.393 475.033L683.312 416.871H605.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_51" fill-rule="evenodd" clip-rule="evenodd"
            d="M736.519 11H828.031L862.547 80.0326L828.031 149.065H736.513L702 79.8915L736.519 11ZM743.232 21.8713L714.154 79.9018L743.238 138.194H821.312L850.393 80.0325L821.312 21.8713H743.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_52" fill-rule="evenodd" clip-rule="evenodd"
            d="M872.519 91H964.031L998.547 160.033L964.031 229.065H872.513L838 159.891L872.519 91ZM879.232 101.871L850.154 159.902L879.238 218.194H957.312L986.393 160.033L957.312 101.871H879.232Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_53" fill-rule="evenodd" clip-rule="evenodd"
            d="M1150.52 78H1242.03L1276.55 147.033L1242.03 216.065H1150.51L1116 146.891L1150.52 78ZM1157.23 88.8713L1128.15 146.902L1157.24 205.194H1235.31L1264.39 147.033L1235.31 88.8713H1157.23Z"
            fill="#E8D9AA" />
        <path id="Vector (Stroke)_54" fill-rule="evenodd" clip-rule="evenodd"
            d="M1148.52 236H1240.03L1274.55 305.033L1240.03 374.065H1148.51L1114 304.891L1148.52 236ZM1155.23 246.871L1126.15 304.902L1155.24 363.194H1233.31L1262.39 305.033L1233.31 246.871H1155.23Z"
            fill="#E8D9AA" />
</template>